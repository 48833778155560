.App {
  background-color: #020303;
  color: #fff;
}

p {
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}

.page-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: 996px;
}

@media screen and (max-width:996px) {
  .page-style {
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
  }
}

.br {
  border: 1px #f00 solid;
}

.hidden-scroll {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.hidden-scroll::-webkit-scrollbar {
  display: none !important;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.w-screen {
  width: 100vw;
}