.joinus-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1018px;

  .p1 {
    height: 50px;
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 36px;
  }

  .p2 {
    font-size: 20px;
    margin-bottom: 51px;
  }

  .input-box {
    display: flex;
    justify-content: space-between;
    height: 58px;
    margin-bottom: 24px;

    .input-style {
      width: calc(50% - 12px);
      background: #191A1E;
      outline: none;
      padding: 0 20px;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      border: none;
    }
  }

  .text-area-style {
    background: #191A1E;
    outline: none;
    padding: 20px;
    box-sizing: border-box;
    color: #fff;
    font-size: 20px;
    border: none;
    height: 165px;
    margin-bottom: 51px;
  }

  .btn {
    background: #1D51FE;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 58px;
    margin: 0 auto;
    cursor: pointer;
  }
}

@media screen and (max-width:996px) {
  .joinus-container {
    max-width: 996px;
    position: relative;
    z-index: 99999999999;
    .p1 {
      height: 28px;
      margin-bottom: 9px;
      font-size: 20px;
    }

    .p2 {
      font-size: 10px;
      margin-bottom: 46px;
    }

    .input-box {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-bottom: 0px;
      height: 92px;

      .input-style {
        height: 32px;
        margin-bottom: 14px;
        width: 289px;
        padding: 0 10px;
        font-size: 10px;
        color: #818181;
        border: none;
      }
    }

    .text-area-style {
      padding: 8px 10px;
      box-sizing: border-box;
      color: #818181;
      font-size: 10px;
      width: 289px;
      height: 250px;
      margin-bottom: 22px;
    }

    .btn {
      width: 105px;
      height: 32px;
      font-size: 12px;
    }
  }
}