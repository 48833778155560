.toc-container {
  min-height: 100vh;
}

.toc-page-1 {
  background: url('http://image.wuyougoodcar.com/renhuo/2023-06-30/BL3yHCLNlyOzPwIlN8SsZdXvhywEgc1gOABXtX7K.png') no-repeat center 20%;
  background-size: cover;
  position: relative;

  .content {
    position: absolute;
    z-index: 99;
  }

  .bag {
    background-color: rgb(0, 0, 0, .4);
    position: absolute;
    z-index: 9;
  }

  .logo {
    width: 159px;
    height: 64px;
    margin-bottom: 23px;
  }

  .p1 {
    height: 25px;
    font-size: 18px;
    margin-bottom: 135px;
  }

  .btn {
    width: 164px;
    height: 45px;
    border-radius: 16px;
    background: #0029FF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
    margin-top: 7px;
  }
}

.toc-page-3 {
  justify-content: flex-end;
  .p1 {
    height: 50px;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .p2 {
    height: 28px;
    font-size: 20px;
    margin-bottom: -52px;
  }
}

.toc-page-4 {
  .p1 {
    height: 50px;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .p2 {
    height: 28px;
    font-size: 20px;
    margin-bottom: 32px;
  }

  .p3 {
    width: 699px;
    line-height: 24px;
    text-align: center;
  }
  .image1{
    display: block;
  }
  .image2{
    display: none;
  }
}

.section-footer {
  height: 550px !important;
}

@media screen and (max-width:996px) {
  .toc-page-1 {
    background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/0L9ryCsgu1X32uSkscqgMeRfxUNmutIr2oZoozPO.png') no-repeat center center;

    .logo {
      width: 104px;
      height: 42px;
      margin-bottom: 37px;
    }

    .p1 {
      height: 18px;
      font-size: 15px;
      margin-bottom: 82px;
    }

    .btn {
      width: 90px;
      height: 28px;
      border-radius: 10px;
      font-size: 14px;
    }
  }

  .toc-page-3 {
    justify-content: center;
    .p1 {
      height: 28px;
      font-size: 20px;
      margin-bottom: 9px;
    }

    .p2 {
      height: 18px;
      font-size: 10px;
      margin-bottom: -30px;
    }
    .image{
      width: 464px;
    }
  }

  .toc-page-4 {
    .p1 {
      height: 28px;
      font-size: 20px;
      margin-bottom: 5px;
    }

    .p2 {
      height: 18px;
      font-size: 10px;
      margin-bottom: -50px;
    }

    .p3 {
      display: none;
    }
    .image1{
      display: none;
    }
    .image2{
      display: block;
    }
  }

  .section-footer {
    height: 100vh !important;
  }
}