.five-box {
  max-width: 1296px;

  p {
    margin: 0;
    line-height: 1;
    display: flex;
    align-items: center;
  }

  .p1 {
    font-size: 36px;
    font-weight: 500;
    height: 50px;
  }

  .p2 {
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .list-item {
      flex: 1;
      margin-right: 1%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 38px;
      box-sizing: border-box;

      img {
        margin-bottom: 43px;
      }

      p {
        line-height: 24px;
        text-align: center;
      }
    }

    .item1 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/ZhGhbaTodFdxiKTW9IBZgGiGZNCoDyQn1rXzceVH.png') no-repeat 0 0;
      height: 451px;
      background-size: 100% 100%;
    }

    .item2 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/zAwfelJYyhOhfzU4BJSI6l0VRWEC0BDjV1yCHRXC.png') no-repeat 0 0;
      height: 345px;
      background-size: 100% 100%;
    }

    .item3 {
      background-color: #2649ff;
      border-radius: 10px;
      height: 304px;
    }

    .item4 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/KoBTWv8xUpuak6CRNQXAKKiJ2JbStjb78kbKRvl1.png') no-repeat 0 0;
      height: 345px;
      background-size: 100% 100%;
    }

    .item5 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/DNkpiCBTiwdYJ9EpGbPQcZrxXF98fpfscdYrSZGu.png') no-repeat 0 0;
      height: 451px;
      margin-right: 0;
      background-size: 100% 100%;
    }
  }
}

@media screen and (max-width:996px) {
  .five-box {
  
    .p1 {
      font-size: 20px;
      font-weight: 400;
      height: 28px;
    }
  
    .p2 {
      font-size: 10px;
      margin-top: 9px;
      line-height: 18px;
      margin-bottom: 10px;
      width: 260px;
      text-align: center;
    }
  
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
  
      .list-item {
        flex: none;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 38px;
        font-size: 8px;
        height: 90px;
        margin-bottom: 7px;
  
        img {
          margin-bottom: 15px;
          height: 28px;
        }
  
        p {
          line-height: 11px;
          text-align: center;
        }
      }
  
      .item1 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/TByHOZUEvhlB7eEv03cr9zttPULgmoVYFAoDF4fj.png') no-repeat 0 0;
        width: 270px;
        background-size: 100% 100%;
      }
  
      .item2 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/cm5kI9MsuXw0fot4c4IBmEumtoNa39eX9IdwuPaP.png') no-repeat 0 0;
        background-size: 100% 100%;
        width: 210px;
      }
  
      .item3 {
        background-color: #2649ff;
        border-radius: 10px;
        height: 90px;
        width: 187px;
      }
  
      .item4 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/ccjnRt8FbF7yWZVPDbDeB1JTT0GLQAm0EHdptZuj.png') no-repeat 0 0;
        background-size: 100% 100%;
        width: 210px;
      }
  
      .item5 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/SeuP12T5aDyUAKpfj154WAY2np5MfKzkNzMZawqL.png') no-repeat 0 0;
        margin-bottom: 0;
        background-size: 100% 100%;
        width: 270px;
      }
    }
  }
}