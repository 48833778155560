.footer-container {
  padding: 76px 0;
  box-sizing: border-box;
  background-color: #000;
  position: relative;
  z-index: 99999999;

  .line {
    height: 1px;
    background-color: rgba(255, 255, 255, .5);
  }
  .icp{
    text-align: center;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    a{
      color: #fff;
      font-size: 14px;
    }
    img{
      margin-right: 6px;
    }
  }

  .footer-content {
    margin: 35px 0;
    height: 328px;
    width: 100%;
    background: #1D51FE;

    .footer-box {
      padding: 38px 0;
      max-width: 1324px;
      min-width: 996px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .box-left {
        display: flex;
        justify-content: space-between;
        border-right: 1px #fff solid;
        width: calc(100% - 356px);
        padding-right: 6%;
        box-sizing: border-box;

        .logo {
          width: 232px;
          height: 33px;
          margin-bottom: 20px;
        }

        .p1 {
          font-size: 12px;
        }

        .p-title {
          font-size: 18px;
          margin-bottom: 14px;
        }

        .p-item {
          color: #C6C6C6;
          font-size: 14px;
          margin-bottom: 12px;
        }
      }

      .box-right {
        width: 270px;
        display: flex;
        font-size: 16px;

        .title {
          width: 24px;
          height: 2px;
          margin-right: 10px;
          border-radius: 29px;
          background: #FFF;
          margin-top: 8px;
        }

        .p1 {
          margin-bottom: 24px;
        }

        .p2 {
          margin-bottom: 22px;
          line-height: 24px;
        }

        .p3 {
          margin-bottom: 21px;
        }

        .qr {
          height: 98px;
          width: 98px;
        }
      }
    }
  }
}
.footer-container-mobile-terminal{
  display: none;
  background-color: #000;
  position: relative;
  z-index: 99999999;
  background: #1D51FE;
  padding: 45px;
  box-sizing: border-box;
  .logo{
    width: 196px;
    margin-bottom: 8px;
  }
  .p1{
    font-size: 10px;
  }
  .line{
    background: #FFF;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 16px;
  }
  .icp{
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    a{
      color: #fff;
      font-size: 12px;
    }
    img{
      margin-right: 4px;
    }
  }
  .function-box{
    display: flex;
    margin-top: 16px;
    .left{
      margin-right: 20px;
    }
    .title{
      font-size: 14px;
    }
    .item{
      font-size: 12px;
      color: #E4E4E4;
      margin-top: 14px;
    }
  }
  .bot-item{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 10px;
    .item-left{
      width: 60px;
    }
  }
  .qr{
    margin: 44px auto 0;
    display: block;
  }
}
@media screen and (max-width:996px) {
  .footer-container-mobile-terminal{
    display: block;
  }
  .footer-container{
    display: none;
  }
}