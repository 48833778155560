.menu-container {
  height: 93px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 972px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .logo {
    width: 132px;
    height: 24px;
  }

  .menu-box {
    display: flex;
    align-items: center;

    .menu-item {
      margin-right: 35px;
      cursor: pointer;
      position: relative;
    }

    .menu-item-active::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #fff;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .login-btn {
    border-radius: 73px;
    background: #0029FF;
    width: 104px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.95);
    margin-left: 80px;
    cursor: pointer;
    text-decoration: none;
  }

  .menu-btn {
    display: none;
    z-index: 99;
  }
}

.menu-mobile {
  width: 128px;
  height: 318px;
  border-end-start-radius: 20px;
  position: fixed;
  top: 0;
  right: -128px;
  background-color: #1D51FE;
  padding-top: 60px;
  box-sizing: border-box;
  transition: all .2s;

  .item {
    // margin-bottom: 6px;
    width: 100%;
    text-align: end;
    height: 40px;
    line-height: 40px;
    padding-right: 0px;
    transition: all .4s;
    box-sizing: border-box;
  }

  .item:hover {
    background-color: #000;  
  }
}

.show-menu {
  right: 0;
  .item {
    padding-right: 20px;
  }
}

@media screen and (max-width:996px) {
  .menu-container {
    width: 100vw;
    padding: 0 20px;
    box-sizing: border-box;
    height: 58px;

    .logo {
      width: 97px;
      height: 18px;
    }

    .menu-box {
      display: none;
    }

    .login-btn {
      display: none;
    }

    .menu-btn {
      display: block;
    }
  }
}