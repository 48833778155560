.cooperate-container {
  position: relative;
  .p1 {
    font-size: 36px;
    height: 50px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .p2 {
    font-size: 20px;
    height: 28px;
    margin-bottom: 71px;
  }

  .list {
    display: flex;

    .list-item {
      margin-right: 153px;

      .item-top {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/3eniUHUBwRze6u8vhRo3OTXatT7mhzQF8DfbmX9O.png') no-repeat 0 0;
        background-size: 100% 100%;
        width: 147px;
        height: 147px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .p3 {
    font-size: 20px;
    height: 28px;
    margin-top: 22px;
    display: flex;
    justify-content: center;
  }

  .btn {
    width: 180px;
    height: 58px;
    border-radius: 15px;
    background: #0029FF;
    font-size: 22px;
    margin-top: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and (max-width:996px) {
  .cooperate-container {
    .p1 {
      font-size: 20px;
      height: 28px;
      margin-bottom: 9px;
    }
  
    .p2 {
      font-size: 10px;
      height: 20px;
      margin-bottom: 10px;
    }
  
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .list-item {
        margin-right: 0px;
        width: 119px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5px;
  
        .item-top {
          background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/3eniUHUBwRze6u8vhRo3OTXatT7mhzQF8DfbmX9O.png') no-repeat 0 0;
          background-size: 100% 100%;
          width: 72px;
          height: 72px;
        }
  
        &:last-child {
          margin-right: 0;
        }
      }
    }
  
    .p3 {
      font-size: 10px;
      height: 18px;
      margin-top: 10px;
    }
  
    .btn {
      width: 121px;
      height: 28px;
      border-radius: 10px;
      font-size: 14px;
      margin-top: 37px;
    }
  }
}