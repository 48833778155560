.popularity-item {
  border-radius: 10px;
  background: #17191C;
  padding: 8px 7px 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .heart {
    position: absolute;
    top: 15px;
    right: 14px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.30);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px)
  }

  .main-img {
    border-radius: 10px;
    width: 218px;
    // height: 218px;
  }

  .p1 {
    font-size: 14px;
    margin-top: 14px;
    font-weight: 700;
  }

  .p2 {
    color: #C1C1C1;
    font-size: 10px;
    margin-top: 3px;
    display: flex;
    align-items: center;

    img {
      margin-left: 3px;
    }
  }

  .bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3;
  }

  .p3 {
    font-size: 14px;
    font-weight: 700;
  }

  .btn {
    border-radius: 87px;
    background: #1D51FE;
    width: 53px;
    height: 35px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 21px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width:996px) {
  .popularity-item {
    border-radius: 8px;
    padding: 5px;

    .heart {
      top: 12px;
      right: 9px;
      width: 30px;
      height: 30px;

      img {
        width: 15px;
      }
    }

    .main-img {
      border-radius: 10px;
      width: 162px;
      // height: 162px;
    }

    .p1 {
      font-size: 10px;
      margin-top: 10px;
      font-weight: 700;
    }

    .p2 {
      font-size: 8px;
      margin-top: 6px;

      img {
        margin-left: 2px;
        width: 10px;
      }
    }

    .bot {
      margin-top: 2px;
    }

    .p3 {
      font-size: 12px;
      font-weight: 700;
    }

    .btn {
      border-radius: 64px;
      width: 55px;
      height: 26px;
      font-size: 12px;
      font-weight: 700;
      margin-right: 0;
    }
  }
}