.find-container {
  padding-top: 115px;
  width: 964px;
  margin: 0 auto;
  min-height: 100vh;

  .find-p1 {
    font-size: 38px;
    font-weight: 700;
    margin: 48px 0 25px;
  }

  .find-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(2, 322px);
    gap: 12px;
  }
}

@media screen and (max-width:996px) {
  .find-container {
    padding-top: 80px;
    width: calc(100% - 40px);

    .find-p1 {
      font-size: 20px;
      font-weight: 600;
      margin: 20px 0 15px;
    }

    .find-box {
      grid-template-columns: repeat(2, 172px);
      // grid-template-rows: repeat(4, 242px);
      gap: 9px;
      margin-bottom: 22px;
    }
  }
}