.rank-container {
  min-height: 100vh;
  width: 965px;
  margin: 0 auto;
  overflow: hidden;

  .page-title {
    border-bottom: 1px #787878 solid;
    margin-top: 155px;
    padding-bottom: 8px;
    margin-bottom: 10px;

    span {
      border-bottom: 2px #fff solid;
      padding-bottom: 8px;
      width: 97px;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .rank-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .rank {
      width: 458px;

      .rank-title {
        display: flex;
        align-items: center;
        color: #C1C1C1;
        font-size: 12px;
        font-weight: 500;

        .collect {
          width: 228px;
        }

        .collect-people {
          width: 115px;
          text-align: center;
        }

        .price {
          width: 115px;
          text-align: center;

        }
      }

      .rank-item {
        @extend .rank-title;
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;
        padding: 12px 0;

        .collect {
          display: flex;
          align-items: center;

          .item-index {
            margin-right: 13px;
          }

          .avatar {
            width: 53px;
            height: 53px;
            border-radius: 50%;
            margin-right: 14px;
          }

          .item-icon {
            margin-left: 3px;
            width: 18px;
            height: 18px;
          }
        }

        .collect-pople {}

        .price {}
      }
    }
  }

  .p1,
  .p2 {
    font-size: 20px;
    text-align: center;
    display: block;
    line-height: 28px;
  }

  .p1 {
    margin-top: 94px;
  }

  .logo {
    width: 227px;
    height: 40px;
    display: block;
    margin: 41px auto 0;
    margin-bottom: 94px;
  }
}

@media screen and (max-width:996px) {
  .rank-container {
    min-height: 100vh;
    width: calc(100% - 24px);
    margin: 0 auto;
    overflow: hidden;

    .page-title {
      margin-top: 80px;

      span {
        width: 97px;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .rank-box {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .rank {
        width: 100%;


        .rank-title {
          display: flex;
          align-items: center;
          color: #C1C1C1;
          font-size: 12px;
          font-weight: 500;

          .collect {
            width: 228px;
          }

          .collect-people {
            width: 115px;
            text-align: center;
          }

          .price {
            width: 115px;
            text-align: center;

          }
        }

        .rank-item {
          @extend .rank-title;
          font-size: 14px;
          font-weight: 700;
          margin-top: 10px;
          padding: 12px 0;

          .collect {
            display: flex;
            align-items: center;

            .item-index {
              margin-right: 13px;
              width: 20px;
            }

            .avatar {
              width: 53px;
              height: 53px;
              border-radius: 50%;
              margin-right: 14px;
            }

            .item-icon {
              margin-left: 3px;
              width: 18px;
              height: 18px;
            }
          }

          .collect-pople {}

          .price {}
        }
      }
    }

    .p1,
    .p2 {
      font-size: 12px;
      text-align: center;
      display: block;
      line-height: 20px;
    }

    .p1 {
      margin-top: 54px;
    }

    .logo {
      width: 147px;
      height: 26px;
      display: block;
      margin: 32px auto 0;
      margin-bottom: 62px;
    }
  }
}