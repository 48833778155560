.tob-container {
  min-height: 100vh;
  width: 100%;

  .page-content {
    width: 99.9%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .3) 5%, rgba(0, 0, 0, .3) 95%, rgba(0, 0, 0, .8) 100%);
  }
}

.tob-page-1 {
  background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/tYqAXDEot9y9baVen2u98s2gib0uBiV1osPH9LuL.png') no-repeat center 20%;
  background-size: cover;

  .logo {
    width: 339px;
    height: 60px;
    margin-bottom: 123px;
  }

  .input-style {
    width: 575px;
    height: 40px;
    border-radius: 999px;
    background: rgba(255, 255, 255, 0.20);
    border: none;
    outline: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    padding-left: 16px;
    margin-bottom: 25px;
  }

  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 47px;

    .list-item {
      width: 96px;
      height: 128px;
      border-radius: 12px;
      margin-right: 12px;
    }
  }

  .btn {
    width: 164px;
    height: 45px;
    border-radius: 16px;
    background: #0029FF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
    margin-top: 7px;
  }
}

.tob-page-3 {
  .p1 {
    height: 50px;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .p2 {
    height: 28px;
    font-size: 20px;
    margin-bottom: 32px;
  }

  .list {
    display: flex;

    .item1,
    .item2,
    .item3,
    .item4,
    .item5 {
      width: 202px;
      height: 307px;
      margin-right: 8px;
      font-size: 18px;
      display: flex;
      align-items: end;
      justify-content: center;
      padding-bottom: 30px;
    }

    .item1 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/mp4EQ2sNFfWnlvh7xacJTvxynRor21VXqiefTa6y.png') no-repeat 0 0;
      background-size: 100% 100%;
    }

    .item2 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/Yrai5bRTXnhA5sm1FtGGx7cQOkct5rpz5bKFZMWz.png') no-repeat 0 0;
      background-size: 100% 100%;
    }

    .item3 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/Vl4KmuKqesJttuoqkzgUIt7tAym1n9c2I8T5Xueh.png') no-repeat 0 0;
      background-size: 100% 100%;
    }

    .item4 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/cIXz4NFVYkmpCX63pBU98BWLkATULbQxpO0ycsjF.png') no-repeat 0 0;
      background-size: 100% 100%;
    }

    .item5 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/8phTjyAFwQdvp9I4KS2mRov9VDGeJEgdYKqdTnfc.png') no-repeat 0 0;
      background-size: 100% 100%;
      margin-right: 0;
    }

  }

  .p3 {
    height: 28px;
    font-size: 20px;
    margin-top: 65px;
  }
}

.section-footer {
  height: 550px !important;
}

@media screen and (max-width:996px) {
  .tob-page-1 {
    background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/tYqAXDEot9y9baVen2u98s2gib0uBiV1osPH9LuL.png') no-repeat center 0;
    background-size: cover;

    .logo {
      display: none;
    }

    .input-style {
      width: 204px;
      height: 26px;
      border-radius: 54px;
      font-size: 9px;
      padding-left: 16px;
      margin-bottom: 23px;
    }

    .list {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 38px;
      width: 100%;
      overflow-x: scroll;

      .list-item {
        width: 94px;
        height: 126px;
        border-radius: 12px;
        margin: 0 6px;
      }
    }

    .btn {
      width: 90px;
      height: 28px;
      border-radius: 10.099px;
      background: #0029FF;
      font-size: 14px;
    }
  }

  .tob-page-3 {
    .p1 {
      height: 28px;
      font-size: 20px;
      margin-bottom: 9px;
    }

    .p2 {
      height: 18px;
      font-size: 10px;
      margin-bottom: 15px;
    }

    .list {
      display: flex;
      flex-direction: column;

      .item1,
      .item2,
      .item3,
      .item4,
      .item5 {
        width: 270px;
        height: 75px;
        margin-right: 0;
        font-size: 10px;
        align-items: center;
        justify-content: start;
        padding-bottom: 0;
        padding-left: 10px;
      }

      .item1 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/G3bZKnblUulrM6GT6JAErFgsumz44STctExYeYBN.png') no-repeat 0 0;
        background-size: 100% 100%;
      }

      .item2 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/Qg528xN1gHJ24ZTFcAUR0Gm0Jg2wbAX7oSYAuZxT.png') no-repeat 0 0;
        background-size: 100% 100%;

      }

      .item3 {
        height: 120px;
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/M3j1F2wDselZwIQmlnu03fTmcqKycTh3sP0AUT9d.png') no-repeat 0 0;
        background-size: 100% 100%;

      }

      .item4 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/22uCBVsaJC6Nc8KfGV9LZCjBNNcRFrA5IPElGkiA.png') no-repeat 0 0;
        background-size: 100% 100%;

      }

      .item5 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/PQT9DEWm1m24NN6622JIqkTX6uURugASkpiraGoi.png') no-repeat 0 0;
        background-size: 100% 100%;

      }

    }

    .p3 {
      height: 8px;
      font-size: 8px;
      margin-top: 15px;
    }
  }

  .section-footer {
    height: 550px !important;
  }

}