.platform {
  max-width: 1041px;

  .bag {
    position: absolute;
    top: 15px;
    right: -200px;
    width: 415px;
    height: 389px;
    border-radius: 415px;
    opacity: 0.2;
    background: #1D51FE;
    filter: blur(45px);
  }

  .p1 {
    color: rgba(255, 255, 255, 0.90);
    font-size: 38px;
    margin-bottom: 51px;
    font-weight: 500;
  }

  .p2 {
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 34px;
    width: 88%;
  }

  .p3 {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
  }

  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;

    .list-item {
      width: 262px;
      height: 142px;
      border-radius: 10px;
      background: url('http://image.wuyougoodcar.com/renhuo/2023-07-13/lwRsifC4wiwNoJyw5IbGB5O9AoJyV2ecQwGj1hhb.png') no-repeat 0 0;
      background-size: 100% 100%;
      padding: 33px 48px 32px;
      box-sizing: border-box;

      p {
        margin: 0;
      }

      .big {
        font-size: 34px;
        font-weight: 600;
        margin-right: 6px;
      }

      .span1 {
        font-size: 16px;
      }

      .span2 {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width:996px) {
  .platform {
    padding: 0 20px;
    box-sizing: border-box;
    min-width: 100vw !important;
    padding-top: 30px;

    .p1 {
      font-size: 20px !important;
      margin-bottom: 9px !important;
    }

    .p2 {
      font-size: 10px !important;
      margin-bottom: 6px !important;
      text-align: center;
      width: 90% !important;
      line-height: 14px;
    }

    .p3 {
      font-size: 8px;
      line-height: 16px;
      width: 100% !important;
      text-align: center !important;
    }

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      .list-item {
        width: 215px;
        height: 86px;
        border-radius: 6px;
        padding: 18px 19px;
        margin-bottom: 20px;

        .big {
          font-size: 24px;
          font-weight: 600;
          margin-right: 6px;
        }

        .span1 {
          font-size: 10px;
        }

        .span2 {
          font-size: 8px;
        }
      }
    }
  }

}