.technology-container{
  min-height: 100vh;
}
.page-1 {
  background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/UGJsNBLzer9BCNLWI33te11BTqmmwBL3o5xq7a4B.png') no-repeat center bottom;
  background-size: cover;
  background-clip: to-lower-case($string: "");

  p {
    margin: 0;
    height: 68px;
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .p1 {
    font-weight: 500;
    font-size: 42px;
  }

  .p2 {
    font-size: 26px;
  }

  .p3 {
    font-size: 22px;
    letter-spacing: 4px;
  }

  .btn {
    width: 164px;
    height: 45px;
    border-radius: 16px;
    background: #0029FF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
    margin-top: 7px;
  }
}

.page-2 {
  max-width: 1014px;
  min-width: 996px;

  .p1 {
    font-size: 36px;
    font-weight: 500;
    height: 50px;
  }

  .p2 {
    font-size: 20px;
    height: 28px;
    margin-bottom: 50px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .tag1,
    .tag2,
    .tag3,
    .tag4,
    .tag5,
    .tag6 {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;

      p {
        height: 25px;
        font-size: 18px;
        font-weight: 600;
        margin: 7px 0;
      }
    }
    .tag1{
      left: 0;
      top: 133px;
    }
    .tag2 {
      left: 110px;
      top: 50%;
      transform: translateY(-50%);
    }
    .tag3 {
      left: 0;
      bottom: 130px;
    }

    .tag4 {
      right: 0;
      top: 133px;
    }

    .tag5 {
      right: 110px;
      top: 50%;
      transform: translateY(-50%);
    }

    .tag6 {
      right: 0;
      bottom: 130px;
    }
  }
}

.section-footer {
  height: 550px !important;
}

@media screen and (max-width:996px) {
  .page-1 {

    .p1 {
      font-size: 24px;
      height: 36px;
    }
  
    .p2 {
      font-size: 15px;
      height: 56px;
      line-height: 20px;
      width: 254px;
      text-align: center;
    }
  
    .p3 {
      display: none;
    }
  }
  
  .page-2 {
    max-width: 996px;
    min-width: 100%;
  
    .p1 {
      font-size: 20px;
      height: 28px;
      margin-bottom: 9px;
    }
  
    .p2 {
      font-size: 10px;
      height: 18px;
      margin-bottom: 50px;
    }
  
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 75px 0;
      .main-img{
        width: 260px;
      }
  
      .tag1,
      .tag2,
      .tag3,
      .tag4,
      .tag5,
      .tag6 {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
  
        p {
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          margin: 7px 0;
        }
      }
      .tag1{
        left: 18px;
        top: 0;
      }
      .tag2 {
        right: 18px;
        top: 0;
        transform: translateX(0);
        left: auto;
      }
      .tag3 {
        top: 63px;
        left: 50%;
        transform: translateX(-50%);
      }
  
      .tag4 {
        right: 0;
        top: auto;
        bottom: 0;
      }
  
      .tag5 {
        left: 18px;
        bottom: 0;
        right: auto;
        top: auto;
        transform: translateY(0);
      }
  
      .tag6 {
        right: 50%;
        bottom: 63px;
        transform: translateX(50%);
      }
    }
  }
}