.recommend-page {
  overflow: hidden;
}

.recommend-container {
  width: 972px;
  margin: 162px auto 0;

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 450px;
    margin-bottom: 72px;

    .left-img {
      // width: 450px;
      height: 450px;
      border-radius: 10px;
    }

    .right-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 450px;
      height: 100% !important;

      .p1 {
        font-weight: 700;
        font-size: 42px;
      }

      .tips-box {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #C1C1C1;
        margin-bottom: 13px;

        img {
          width: 25px;
          height: 25px;
          margin-left: 6px;
        }
      }

      .top-content {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.1);
        padding: 18px;
        box-sizing: border-box;
        width: 502px;

        .cost {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .moon,
          .year {
            width: 224px;
            height: 104px;
            border-radius: 9px;
            background: rgba(255, 255, 255, 0.1);
            position: relative;
            overflow: hidden;

            .p2 {
              font-weight: 500;
              margin: 30px 0 0 16px;
              line-height: 1;
            }

            .p3 {
              font-weight: 600;
              margin: 16px 0 0 16px;
              font-size: 30px;
              line-height: 1;
            }

            img {
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }

        .btn-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0 20px;

          .buy-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            border-radius: 9px;
            background: #FFF;
            color: #303030;
            font-size: 22px;
            font-weight: 600;
            width: 100%;
            height: 68px;
            cursor: pointer;

            img {
              margin-right: 5px;
            }
          }
        }

        .times-box {
          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 9px;
          background: rgba(255, 255, 255, 0.1);
          height: 89px;

          .times-side,
          .times-center {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, 0.80);
            font-weight: 500;

            .p4 {
              font-size: 12px;
              margin-bottom: 20px;
            }

            .p5 {
              font-size: 16px;
            }
          }

          .times-center {
            border-left: 1px rgba(255, 255, 255, 0.1) solid;
            border-right: 1px rgba(255, 255, 255, 0.1) solid;
          }
        }
      }
    }
  }

  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-name {
      font-size: 20px;
      font-weight: 700;
    }

    .title-btn {
      width: 63px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;

      border-radius: 87px;
      border: 1px solid #787878;
      background: rgba(255, 255, 255, 0.10);
      backdrop-filter: blur(2);
      cursor: pointer;
    }
  }

  .popularity-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(2, 322px);
    gap: 12px;
    margin-top: 18px;
    margin-bottom: 51px;
  }
}

@media screen and (max-width:996px) {
  .recommend-page {
    overflow: hidden;
  }

  .recommend-container {
    // width: 972px;
    width: calc(100% - 40px);
    margin: 80px auto 0;

    .top-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-bottom: 72px;

      .left-img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      .right-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;


        .p1 {
          font-weight: 500;
          font-size: 24px;
          margin-top: 26px;
          margin-bottom: 19px;
        }

        .tips-box {
          font-size: 16px;
          margin-bottom: 13px;

          img {
            width: 17px;
            height: 17px;
            margin-left: 3px;
          }
        }

        .top-content {
          padding: 8px;
          width: 100%;

          .cost {

            .moon,
            .year {
              width: calc(50% - 4px);
              height: 75px;

              .p2 {
                margin: 24px 0 0 11px;
                font-size: 10px;
              }

              .p3 {
                margin: 12px 0 0 11px;
                font-size: 20px;
              }

              img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 44px;
              }
            }
          }

          .btn-box {
            margin: 8px 0 8px;

            .buy-btn {
              font-size: 16px;
              height: 50px;

              img {
                height: 20px;
                width: 20px;
              }
            }
          }

          .times-box {
            height: 64px;

            .times-side,
            .times-center {
              .p4 {
                font-size: 8px;
                margin-bottom: 14px;
              }

              .p5 {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .title-box {

      .title-name {
        font-size: 16px;
        font-weight: 600;
      }

      .title-btn {
        width: 51px;
        height: 25px;
        border-radius: 65px;
      }
    }

    .popularity-box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      // grid-template-rows: repeat(2, 322px);
      gap: 12px;
      margin-top: 18px;
      margin-bottom: 51px;
    }
  }
}