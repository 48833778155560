.home-container {
  min-height: 100vh;
}

.pages-1 {
  background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/WFg7EUKXTLa32i0Ie5Qyu7x9WeNYencwj1PgbiJn.png') no-repeat center 20%;
  background-size: cover;

  p {
    margin: 0;
    height: 68px;
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .p1 {
    font-size: 42px;
    font-weight: 500;
  }

  .p2 {
    font-size: 26px;
  }

  .p3 {
    font-size: 22px;
    letter-spacing: 4px;
  }

  .btn-box {
    display: flex;
  }

  .btn-icon1 {
    width: 20px;
    margin-right: 4px;
  }

  .btn-icon2 {
    width: 24px;
    margin-right: 4px;
  }

  .btn {
    width: 140px;
    height: 42px;
    border-radius: 16px;
    background: #000;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
    margin-top: 7px;
    margin: 0 8px;
    color: #FFF;
    font-weight: 500;
  }
}

.pages-3 {
  .p1 {
    font-size: 36px;
    height: 50px;
    margin-bottom: 16px;
    font-weight: 500;
  }

  .p2 {
    height: 28px;
    font-size: 20px;
    margin-bottom: 40px;
  }

  .img-box {
    border-radius: 19px;
    background-color: #111;
    padding: 12px;
    margin-bottom: 56px;
    border: 1px #666 solid;

    .video-style {
      width: 438px;
      height: 328px;
      background-color: #000;
    }
  }

  .p3 {
    width: 644px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
}

.pages-4 {
  max-width: 1040px;

  p {
    margin: 0;
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .p1 {
    font-size: 36px;
    margin-bottom: 5px;
    height: 50px;
    font-weight: 500;
  }

  .p2 {
    height: 28px;
    font-size: 20px;
    margin-bottom: 32px;
  }

  .page-4-list {
    display: flex;
    height: 307px;
    width: 100%;

    .list-item {
      width: 14%;
      display: flex;
      align-items: end;
      padding: 0 0 30px 12px;
      box-sizing: border-box;
      font-size: 18px;
    }

    .list-item-center {
      @extend .list-item;
      width: 47%;
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/smvrB2w13YWfNnNxGA3QplbDA9jqqMtAbrUWyJpO.png') no-repeat 0 0;
      background-size: 100% 100%;
      text-align: center;
      justify-content: center;
    }

    .item1 {
      background: url("http://image.wuyougoodcar.com/renhuo/2023-06-29/EM01N2oegn6OjEd46NDmWBoHmLn7wPCDNVoansE2.png") no-repeat 0 0;
    }

    .item2 {
      background: url("http://image.wuyougoodcar.com/renhuo/2023-06-29/RI9O5bQ6GTGoXx0BcWMhzbBdEvPZMGz2OquvQ6SF.png") no-repeat 0 0;
    }

    .item3 {
      background: url("http://image.wuyougoodcar.com/renhuo/2023-06-29/kaS2pQtpmjWu8lrLwh8mPeJ8t2FkcjtHwlp01rWy.png") no-repeat 0 0;
    }

    .item4 {
      background: url("http://image.wuyougoodcar.com/renhuo/2023-06-29/rb9g9uGzyKzZMYRos78dpDcYR0vaSsff6U9cidvw.png") no-repeat 0 0;
    }
  }

  .p3 {
    height: 28px;
    font-size: 20px;
    margin-top: 65px;
  }
}

.page-5 {
  max-width: 1041px;

  .p1 {
    height: 50px;
    font-size: 36px;
    margin-bottom: 7px;
    font-weight: 500;
  }

  .p2 {
    font-size: 20px;
    text-align: center;
    width: 645px;
    line-height: 32px;
  }

  .content {
    display: flex;
    justify-content: center;
    border-radius: 30px;
    border: 1px dashed #FFF;
    background: #1D1E26;
    margin-top: 27px;
    padding: 25px 0;
    box-sizing: border-box;

    .big {
      display: block;
    }

    .item {
      display: none;
    }
  }
}

.page-6 {
  max-width: 1018px;

  .p1 {
    height: 50px;
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 36px;
  }

  .p2 {
    width: 630px;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
  }

  .image {
    margin-top: 51px;
    display: block;
  }

  .image1 {
    display: none;
  }

  .image2 {
    display: none;
  }
}

.section-footer {
  height: 550px !important;
}

@media screen and (max-width:996px) {
  .pages-1 {
    .p1 {
      font-size: 26px;
      height: 36px;
      margin-bottom: 12px;
    }

    .p2 {
      font-size: 16px;
      height: 21px;
    }

    .p3 {
      font-size: 22px;
      height: 21px;
      letter-spacing: 4px;
      margin-top: 12px;
    }

    .btn-icon1 {
      width: 14px;
      margin-right: 4px;
    }

    .btn-icon2 {
      width: 16px;
      margin-right: 4px;
    }

    .btn {
      width: 90px;
      height: 28px;
      border-radius: 10px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.20);
      font-size: 14px;
      margin-top: 12px;
      font-size: 12px;
    }
  }

  .pages-3 {
    .p1 {
      font-size: 20px;
      height: 28px;
      margin-bottom: 16px;
      width: 90%;
      text-align: center;
      line-height: 28px;
    }

    .p2 {
      height: 18px;
      font-size: 10px;
      margin-bottom: 40px;
    }

    .img-box {
      border-radius: 10px;
      background-color: #111;
      padding: 6px;
      margin-bottom: 56px;
      border: 1px #666 solid;

      .video-style {
        width: 220px;
        height: 164px;
      }
    }

    .p3 {
      width: 90%;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
    }
  }

  .pages-4 {
    .p1 {
      font-size: 20px;
      margin-bottom: 9px;
      height: 28px;
    }

    .p2 {
      height: 18px;
      font-size: 10px;
      margin-bottom: 10px;
    }

    .page-4-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 540px;

      .list-item {
        width: 289px;
        display: flex;
        align-items: center;
        padding: 0 0 0 20px;
        font-size: 10px;
        height: 90px;
      }

      .list-item-center {
        @extend .list-item;
        height: 180px;
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/AOGqm183nUo5K5RHuC1qjQEXyu1hZwZVeh99SpaM.png') no-repeat center center;
        align-items: center;
        justify-content: start;
        padding: 0 0 0 20px;
      }

      .item1 {
        background: url("http://image.wuyougoodcar.com/renhuo/2023-07-02/auCk27dbpop8KQTvhrKJmbtm8CNBF5lvH0wb4hZj.png") no-repeat 0 0;
        // background-size: 100% 100%;

      }

      .item2 {

        background: url("http://image.wuyougoodcar.com/renhuo/2023-07-02/WRTtkA5FdQAbbZFTjZYhReGy0d8TnjIdfoF8H80E.png") no-repeat center center;
        // background-size: cover;

      }

      .item3 {
        background: url("http://image.wuyougoodcar.com/renhuo/2023-07-02/PCFYSFsOL9qFO08d9A26i4VfOSUBYlEnxoaCxSdK.png") no-repeat 0 0;
        // background-size: 100% 100%;

      }

      .item4 {
        background: url("http://image.wuyougoodcar.com/renhuo/2023-07-02/kI5AQ2U5IFkPNhywpVDRTcN0RuEIRvThJYeqe76p.png") no-repeat 0 0;
        // background-size: 100% 100%;

      }
    }

    .p3 {
      height: 11px;
      font-size: 8px;
      margin-top: 20px;
    }
  }

  .page-5 {
    max-width: 996px;

    .p1 {
      height: 28px;
      font-size: 20px;
      margin-bottom: 9px;
    }

    .p2 {
      font-size: 10px;
      width: 260px;
      line-height: 18px;
    }

    .content {
      flex-wrap: wrap;
      margin-top: 20px;
      padding: 20px 15px;
      width: 289px;

      .big {
        display: none;
      }

      .item {
        display: block;
        margin-bottom: 12px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .page-6 {
    max-width: 996px;

    .p1 {
      height: 28px;
      margin-bottom: 9px;
      font-size: 20px;
    }

    .p2 {
      width: 200px;
      font-size: 10px;
      line-height: 18px;
    }

    .image {
      display: none;
    }

    .image1,
    .image2 {
      display: block;
      width: 289px;
      height: 202px;
    }

    .image2 {
      margin-top: 30px;
    }
  }

  .section-footer {
    height: 100vh !important;
  }
}