.mask-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.qr-box {
  background-color: #fff;
  padding: 46px 40px 66px;
  border-radius: 30px;
  width: 477px;
  box-sizing: border-box;

  .p1 {
    color: #000;
    font-size: 20px !important;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .p2 {
    color: #000;
    font-weight: 500;
    font-size: 14px !important;
  }

  .p3 {
    color: #A1A1A1;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    display: block;
  }

  .qr-img {
    width: 190px;
    height: 190px;
    margin: 52px auto 36px;
    display: block;
  }
}

@media screen and (max-width:996px) {
  
  .qr-box {
    padding: 0;
    border-radius: 18px;
    width: 185px;
    height: 181px;
    overflow: hidden;

    .p1 {
      font-size: 12px !important;
      font-weight: 700;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      margin-top: 20px !important;
    }

    .p2 {
      font-weight: 400;
      font-size: 8px !important;
      display: flex;
      justify-content: center;
      color: #000;
    }

    .p3 {
      font-weight: 400;
      color: #A1A1A1;
      font-size: 6px !important;
    }

    .qr-img {
      width: 78px;
      height: 78px;
      margin: 10px auto 20px;
    }
  }
}