.top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  position: relative;

  .search-icon {
    position: absolute;
    left: 12px;
    top: 12px;
  }

  p {
    cursor: pointer;
  }

  .input-style {
    height: 48px;
    border-radius: 100px;
    background: #17191C;
    border: none;
    outline: none;
    color: #C1C1C1;
    width: 85%;
    padding-left: 44px;
    box-sizing: border-box;
  }
}

@media screen and (max-width:996px) {
  .top-box {

    .search-icon {
      position: absolute;
      left: 8px;
      top: 4px;
      width: 18px;
    }

    p {
      display: none;
    }

    .input-style {
      height: 26px;
      width: 65%;
      padding-left: 28px;
    }
  }
}