.tog-container {
  min-height: 100vh;
}

.tog-page-1 {
  background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/13RKTCMceVNgyRUaN9j7kGIIbOiX0QbOp2Mk3nPx.png') no-repeat center 20%;
  background-size: cover;

  .logo {
    width: 339px;
    height: 60px;
    margin-bottom: 123px;
  }
}

.tog-page-3 {
  .p1 {
    height: 50px;
    font-size: 36px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .p2 {
    height: 28px;
    font-size: 20px;
    margin-bottom: 32px;
  }

  .list {
    display: flex;

    .item1,
    .item2,
    .item3,
    .item4,
    .item5 {
      width: 202px;
      height: 307px;
      margin-right: 8px;
      font-size: 18px;
      display: flex;
      align-items: end;
      justify-content: center;
      padding-bottom: 30px;
    }

    .item1 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-07-08/Bog4jgJVxMU9BVTeZppEcZraCX7A1eQWWGdv15eL.png') no-repeat 0 0;
      background-size: 100% 100%;
    }


    .item2 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/Vl4KmuKqesJttuoqkzgUIt7tAym1n9c2I8T5Xueh.png') no-repeat 0 0;
      background-size: 100% 100%;
    }

    .item3 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/cIXz4NFVYkmpCX63pBU98BWLkATULbQxpO0ycsjF.png') no-repeat 0 0;
      background-size: 100% 100%;
    }

    .item4 {
      background: url('http://image.wuyougoodcar.com/renhuo/2023-06-29/8phTjyAFwQdvp9I4KS2mRov9VDGeJEgdYKqdTnfc.png') no-repeat 0 0;
      background-size: 100% 100%;
      margin-right: 0;
    }

  }

  .p3 {
    height: 28px;
    font-size: 20px;
    margin-top: 65px;
  }
}

.tog-page-5 {
  .p1 {
    font-size: 36px;
    height: 50px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .p2 {
    font-size: 20px;
    height: 28px;
    margin-bottom: 70px;
  }

  .list {
    display: flex;

    .list-item {
      width: 146px;
      margin: 0 7px;

      .p3 {
        font-size: 20px;
        margin-top: 22px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.section-footer {
  height: 550px !important;
}

@media screen and (max-width:996px) {
  .tog-page-1 {
    background: url('http://image.wuyougoodcar.com/renhuo/2023-07-11/FnNKE69ZwnGsQnairUMeshom3b6zfdUhZJFH5Fms.png') no-repeat 0 0;
    background-size: cover;

    .logo {
      // display: none;
      width: 271px;
      height: 48px;
      margin-bottom: 0;
    }
  }

  .tog-page-3 {
    .p1 {
      height: 28px;
      font-size: 20px;
      margin-bottom: 9px;
    }

    .p2 {
      height: 18px;
      font-size: 10px;
      margin-bottom: 25px;
    }

    .list {
      display: flex;
      flex-direction: column;

      .item1,
      .item2,
      .item3,
      .item4 {
        width: 289px;
        height: 90px;
        margin-right: 0;
        font-size: 10px;
        align-items: center;
        justify-content: start;
        padding-bottom: 0;
        padding-left: 10px;
      }

      .item1 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-08/Bog4jgJVxMU9BVTeZppEcZraCX7A1eQWWGdv15eL.png') no-repeat center center;
        background-size: cover;
      }

      .item2 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/M3j1F2wDselZwIQmlnu03fTmcqKycTh3sP0AUT9d.png') no-repeat 0 0;
        background-size: cover;
      }

      .item3 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/22uCBVsaJC6Nc8KfGV9LZCjBNNcRFrA5IPElGkiA.png') no-repeat 0 0;
        background-size: cover;
      }

      .item4 {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-07-02/PQT9DEWm1m24NN6622JIqkTX6uURugASkpiraGoi.png') no-repeat 0 0;
        background-size: cover;
      }

    }

    .p3 {
      height: 8px;
      font-size: 8px;
      margin-top: 31px;
    }
  }

  .tog-page-5 {
    .p1 {
      font-size: 20px;
      height: 28px;
      margin-bottom: 9px;
      font-weight: 500;
    }

    .p2 {
      font-size: 10px;
      height: 18px;
      margin-bottom: 20px;
    }

    .list {
      display: flex;
      width: calc(100% - 32px);
      flex-wrap: wrap;
      justify-content: space-between;

      .list-item {
        width: 27vw;
        margin: 0 0 5px 0;
        img{
          width: 100%;
        }

        .p3 {
          font-size: 12px;
          margin-top: 12px;
        }
      }
    }
  }

  .section-footer {
    height: 550px !important;
  }

}