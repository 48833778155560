.starlight {
  background-color: #000;
  height: 100vh;
  overflow: hidden;

  .starlight-content-box {
    overflow-y: scroll;
    padding-top: 115px;
    height: 100%;
    box-sizing: border-box;
    margin-right: -18px;
  }

  .starlight-content {
    width: 965px;
    margin: 0 auto;

    .swiper1-box {
      height: 313px;
      margin-top: 28px;

      .swiper1-item {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-06-30/wMIDGP2Yp3Ct0fegVQN9YQ1pvQdl2p0egNQG0FGK.png') no-repeat 0 0;
        padding: 68px 0 0 47px;
        color: #000;

        .p1 {
          font-size: 28px;
          line-height: 34px;
          width: 237px;
          margin-bottom: 6px;
          font-weight: 700;
        }

        .p2 {
          font-size: 10px;
        }

        .btn {
          width: 98px;
          height: 42px;
          border-radius: 88px;
          border: 1px solid #fff;
          background: rgba(255, 255, 255, 0.30);
          backdrop-filter: blur(2px);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 47px;
          cursor: pointer;
        }
      }
    }

    .recommend {
      display: flex;
      justify-content: space-between;
      margin: 52px 0 12px;

      .p1 {
        font-size: 20px;
        font-weight: 700;
      }

      .more {
        width: 63px;
        height: 33px;
        border-radius: 87px;
        border: 1px solid #787878;
        background: rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(2px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 1;
        cursor: pointer;
      }
    }

    .swiper2-box {
      height: 294px;
      width: calc(100% + 12px);
      position: relative;

      .swiper2-item {
        background: url('http://image.wuyougoodcar.com/renhuo/2023-06-30/KR3REwayspsNtKdQUobstGzfhDd1Z8MHrqsLkP33.png') no-repeat 0 0;
        width: 232px;
        border-radius: 12px;
      }

      .pre {
        position: absolute;
        left: -2px;
        top: 50%;
        z-index: 99999;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .next {
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 99999;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .rank {
      border-bottom: 1px solid #787878;
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      margin-bottom: 10px;

      .rank-title {
        font-size: 20px;
        width: 97px;
        border-bottom: 2px #fff solid;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: -1px;
      }

      .rank-right {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      .time,
      .more {
        width: 65px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 87px;
        border: 1px solid#787878;
        background: rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(2px);
        cursor: pointer;
        font-size: 12px;
        margin: 0 14px 0 25px;
      }
    }

    .rank-box {
      display: flex;

      .rank-side {
        width: 50%;
      }

      .title-box {
        color: #C1C1C1;
        font-size: 12px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .item1 {
        width: 60%;
        display: flex;
        align-items: center;

        .avatar {
          border-radius: 100%;
          margin: 0 14px 0 13px;
          width: 52px;
          height: 52px;
        }

        .icon {
          margin-left: 3px;
        }
      }

      .item2 {
        width: 20%;
      }

      .item3 {
        width: 20%;
      }
    }

    .popularity {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      // grid-template-rows: repeat(2, 322px);
      gap: 12px;
    }
  }
}

// -------------------------------------------------------------
@media screen and (max-width:996px) {
  .starlight {

    .starlight-content-box {
      padding-top: 65px;
      margin-right: 0;
    }

    .starlight-content {
      width: calc(100% - 40px);

      .swiper1-box {
        height: 120px;
        margin-top: 13px;

        .swiper1-item {
          background: url('http://image.wuyougoodcar.com/renhuo/2023-06-30/wMIDGP2Yp3Ct0fegVQN9YQ1pvQdl2p0egNQG0FGK.png') no-repeat 0 0;
          background-size: cover;
          padding: 26px 0 0 17px;

          .p1 {
            font-size: 10px;
            line-height: 12px;
            width: 87px;
            margin-bottom: 2px;
          }

          .p2 {
            font-size: 4px;
          }

          .btn {
            width: 35px;
            height: 16px;
            border-radius: 32px;
            margin-top: 17px;
            font-size: 6px;
          }
        }
      }

      .recommend {
        margin: 18px 0 11px;

        .p1 {
          font-size: 16px;
          font-weight: 700;
        }

        .more {
          width: 51px;
          height: 25px;
          border-radius: 66px;
        }
      }

      .swiper2-box {
        // height: 145px;
        height: 120px;
        width: calc(100% + 6px);
        position: relative;

        .swiper2-item {
          // background: url('http://image.wuyougoodcar.com/renhuo/2023-06-30/KR3REwayspsNtKdQUobstGzfhDd1Z8MHrqsLkP33.png') no-repeat center center;
          background-size: 100% 100%;
          width: calc(100% - 6px);
        }
      }

      .rank {
        border-bottom: none;
        margin-top: 21px;
        margin-bottom: 20px;

        .rank-title {
          font-size: 16px;
          border-bottom: none;
        }

        .rank-right {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

        }

        .time {
          display: none;
        }

        .more {
          width: 52px;
          height: 25px;
          border-radius: 66px;
          font-size: 10px;
          margin: 0;
        }
      }

      .rank-box {
        display: block;

        .rank-side {
          width: 100%;
        }

        .hidden {
          display: none;
        }

        .title-box {
          margin-bottom: 14px;
        }

        .content-box {

          height: 60px;
          font-size: 10px;
          margin-bottom: 8px;
        }

        .item1 {

          .avatar {
            margin: 0 10px;
            width: 40px;
            height: 40px;
          }
        }

        .item2 {
          width: 20%;
          text-align: center;
          display: block;
        }

        .item3 {
          width: 20%;
          text-align: center;
          display: block;
        }
      }

      .popularity {
        grid-template-columns: repeat(2, 172px);
        // grid-template-rows: repeat(4, 242px);
        gap: 9px;
        margin-bottom: 22px;
      }
    }
  }
}